var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.formList, function (item, index) {
        return [
          _c(
            "el-form",
            { key: index, attrs: { "label-width": "125px" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "企业订单编号" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: item.orderNumber,
                              callback: function ($$v) {
                                _vm.$set(item, "orderNumber", $$v)
                              },
                              expression: "item.orderNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商超订单编号" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: item.kaOrderNumber,
                              callback: function ($$v) {
                                _vm.$set(item, "kaOrderNumber", $$v)
                              },
                              expression: "item.kaOrderNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户编号" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: item.kaCode,
                              callback: function ($$v) {
                                _vm.$set(item, "kaCode", $$v)
                              },
                              expression: "item.kaCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户名称" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: item.kaName,
                              callback: function ($$v) {
                                _vm.$set(item, "kaName", $$v)
                              },
                              expression: "item.kaName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据状态" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: item.orderStatus,
                              callback: function ($$v) {
                                _vm.$set(item, "orderStatus", $$v)
                              },
                              expression: "item.orderStatus",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "请求交货日期" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: item.deliveryDate,
                              callback: function ($$v) {
                                _vm.$set(item, "deliveryDate", $$v)
                              },
                              expression: "item.deliveryDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据状态信息" } },
                        [
                          _c("el-input", {
                            staticClass: "inputColor",
                            attrs: { type: "textarea", readonly: "" },
                            model: {
                              value: item.orderStatusMsg,
                              callback: function ($$v) {
                                _vm.$set(item, "orderStatusMsg", $$v)
                              },
                              expression: "item.orderStatusMsg",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { key: index + "1", staticClass: "form" },
            [
              _c(
                "vxe-table",
                {
                  ref: "xTable",
                  refInFor: true,
                  attrs: {
                    resizable: "",
                    "show-overflow": "",
                    "show-footer-overflow": "",
                    "highlight-hover-row": "",
                    "highlight-current-row": "",
                    data: item.goodsVos,
                  },
                },
                [
                  _c("vxe-table-column", {
                    attrs: {
                      field: "orderNumber",
                      title: "企业订货单编号",
                      width: "130",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "goodsName",
                      title: "企业产品名称",
                      width: "130",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "kaGoodsCode",
                      title: "客户产品编号",
                      width: "130",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "kaGoodsName",
                      title: "客户产品名称",
                      width: "110",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "curUnit",
                      title: "当前单位",
                      width: "110",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "curUnitOrderQuantity",
                      title: "当前单位订货数量",
                      width: "130",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "ratio",
                      title: "单位转换比例系数",
                      width: "130",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "curCompanyUnit",
                      title: "企业当前单位",
                      width: "110",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "curCompanyUnitOrderQuantity",
                      title: "企业当前单位订货数量",
                      width: "150",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "factoryName",
                      title: "工厂",
                      width: "130",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "orderStatus",
                      title: "单据状态",
                      width: "130",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "orderStatusMsg",
                      title: "单据状态信息",
                      width: "150",
                      "class-name": "inputColor",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      }),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small", icon: "el-icon-check" },
              on: { click: _vm.dialog_btnConfirm },
            },
            [_vm._v("提交 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-close" },
              on: { click: _vm.dialog_btnClose },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }