<!-- 推送到SAP -->
<template>
  <div>
    <template v-for="(item, index) in formList">
      <el-form label-width="125px" :key="index">
        <el-row>
          <el-col :span="8">
            <el-form-item label="企业订单编号">
              <el-input readonly v-model="item.orderNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商超订单编号">
              <el-input readonly v-model="item.kaOrderNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客户编号">
              <el-input readonly v-model="item.kaCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客户名称">
              <el-input readonly v-model="item.kaName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="单据状态">
              <el-input readonly v-model="item.orderStatus"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="请求交货日期">
              <el-input readonly v-model="item.deliveryDate"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="单据状态信息">
              <el-input type="textarea" readonly v-model="item.orderStatusMsg" class="inputColor"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div :key="index + '1'" class="form">
        <vxe-table ref="xTable" resizable show-overflow show-footer-overflow highlight-hover-row highlight-current-row :data="item.goodsVos">
          <vxe-table-column field="orderNumber" title="企业订货单编号" width="130"></vxe-table-column>
          <vxe-table-column field="goodsName" title="企业产品名称" width="130"></vxe-table-column>
          <vxe-table-column field="kaGoodsCode" title="客户产品编号" width="130"></vxe-table-column>
          <vxe-table-column field="kaGoodsName" title="客户产品名称" width="110"></vxe-table-column>
          <vxe-table-column field="curUnit" title="当前单位" width="110"></vxe-table-column>
          <vxe-table-column field="curUnitOrderQuantity" title="当前单位订货数量" width="130"></vxe-table-column>
          <vxe-table-column field="ratio" title="单位转换比例系数" width="130"></vxe-table-column>
          <vxe-table-column field="curCompanyUnit" title="企业当前单位" width="110"></vxe-table-column>
          <vxe-table-column field="curCompanyUnitOrderQuantity" title="企业当前单位订货数量" width="150"></vxe-table-column>
          <vxe-table-column field="factoryName" title="工厂" width="130"></vxe-table-column>
          <vxe-table-column field="orderStatus" title="单据状态" width="130"></vxe-table-column>
          <vxe-table-column field="orderStatusMsg" title="单据状态信息" width="150" class-name="inputColor"></vxe-table-column>
        </vxe-table>
      </div>
    </template>
    <div class="dialog-footer">
      <el-button type="primary" @click="dialog_btnConfirm" size="small" icon="el-icon-check">提交 </el-button>
      <el-button type="danger" @click="dialog_btnClose" size="small" icon="el-icon-close">关闭</el-button>
    </div>
  </div>
</template>
<script>
import request from '@/found/utils/request';
import Form from '@/found/components/form';

export default {
  extends: Form,
  data() {
    return {
      formList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取推送数据
    getData() {
      request.post('/kms/kmsorderform/findPushList', this.formConfig.selectionList).then((res) => {
        this.formList = res.result;
      });
    },
    // 关闭
    dialog_btnClose() {
      this.$emit('onClose');
    },
    // 提交
    dialog_btnConfirm() {
      request.post('/kms/kmsorderform/push', { ids: this.formConfig.selectionList }, { headers: { functionCode: 'order_display_new_list' } }).then((res) => {
        this.$message.success('操作成功');
        this.$emit('onClose');
        this.$emit('onGetList');
      });
    },
  },
};
</script>
<style lang="less" scoped>
  .form{
    margin: 20px 0;
    width: 100%;
  }
  /deep/.inputColor{
    .vxe-cell--label{
      color: red;
    }
  }
  /deep/ .el-textarea__inner{
    color: red;
  }
  .dialog-footer {
    position: absolute;
    bottom: 0;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 99;
    background-color: white;
  }
</style>
